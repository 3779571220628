import transformActionTarget from '../../utils/transformActionTarget';

const jsonToProps = ({ quick_access, question_message, ...rest }) => {
  const incomingQuestionsModel = rest.questions;

  if (quick_access && quick_access.actions) {
    quick_access.actions.forEach(element => {
      transformActionTarget(element, { controlled: 'true' });
    });
  }

  return {
    quick_access,
    question_message,
    ...rest,
    ...incomingQuestionsModel,
  };
};

export default jsonToProps;
