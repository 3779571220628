import React from 'react';
import { bool, string, shape } from 'prop-types';
import { trackEvent } from '../../lib/tracking';
import withTooltip from '../tooltip/with-tooltip';
import withCardTooltip from '../card-tooltip/with-card-tooltip';
import { ACTION_TYPES, BUTTON_TYPES } from '../buybox/actions/utils/action-types';
import BuildButtonAction from '../buybox/actions/builders/build-button-action';

const namespace = 'ui-pdp-action';

const buildAction = (actionProps, onClickHandler) => {
  const buttonProps = {};
  buttonProps['aria-disabled'] = actionProps.ariaDisabled;
  buttonProps.tabIndex = actionProps.tabIndex;
  buttonProps.form = actionProps.form;
  buttonProps.formAction = actionProps.target;
  buttonProps.type = BUTTON_TYPES.SUBMIT;
  return (
    <BuildButtonAction
      buttonProps={buttonProps}
      onClickHandler={onClickHandler}
      called_from="questions_component"
      {...actionProps}
    />
  );
};

const Action = ({ showQuestionsAiModal, deviceType, redirectToLogin, saveFrontendStatsd, ...props }) => {
  const onClickHandler = e => {
    if (props.is_login_required && redirectToLogin) {
      redirectToLogin(props.itemId, props.featureName, props.loginType);
    }

    if (props.actionType === ACTION_TYPES.ASK_QUESTIONS_AI) {
      e.preventDefault();
      const { target, disabled } = props;

      showQuestionsAiModal({
        target,
        disabled,
        called_from: 'questions_component',
        source: 'vpp',
      });
    }
    trackEvent(props.track);
  };

  return buildAction({ ...props, deviceType, saveFrontendStatsd }, onClickHandler);
};

Action.propTypes = {
  disabled: bool,
  track: shape({}),
  type: string.isRequired,
  deviceType: string,
  form: string,
};

Action.defaultProps = {
  disabled: false,
  track: null,
  deviceType: null,
  should_raise_errors: false,
  showVariationsError: null,
  updateBudgetComponents: null,
  onClick: null,
  tabIndex: null,
  form: null,
};

export default React.memo(withCardTooltip(withTooltip(Action, `${namespace}__tooltip`), `${namespace}__card-tooltip`));
