import React from 'react';
import { shape, string } from 'prop-types';
import QuestionsList from './questions-list';
import ActionRow from '../action-row';

const namespace = 'ui-pdp-questions__questions-others-question-modal';

const OthersQuestionsMobile = ({ questionsList, show_more }) => (
  <div className={namespace}>
    <QuestionsList className="mt-8" questionsList={questionsList} showTitle />
    {show_more && show_more.label ? (
      <ActionRow
        className={`${namespace}__action`}
        linkClassName="mt-20"
        label={show_more.label.text}
        url={show_more.target}
        modifier="secondary"
      />
    ) : null}
  </div>
);

OthersQuestionsMobile.propTypes = {
  questionsList: shape({
    show_more: shape({
      label: shape({
        text: string,
        color: string,
      }),
      target: string,
    }),
  }).isRequired,
  show_more: shape({
    label: shape({
      text: string,
      color: string,
    }),
    target: string,
  }),
};

OthersQuestionsMobile.defaultProps = {
  show_more: null,
};

export default OthersQuestionsMobile;
