import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, func, node } from 'prop-types';
import Questions from './questions';
import QuestionsList from './questions-list';
import NoQuestions from './questions-no-questions';
import OthersQuestionsMobile from './questions-others-questions.mobile';
import jsonToProps from './jsonToProps';
import ActionBuyBox from '../buybox/actions/actions-buybox';
import { Recaptchav2 } from '../recaptcha';
import QuestionWarningMessage from '../message/question-warning';
import Action from './questions-action';

const namespace = 'ui-pdp-questions';

const QuestionsMobile = ({
  quick_access,
  question_message,
  fetchContactInfo,
  responseToken,
  redirectToLogin,
  updateTokenResponse,
  recaptchaPublicKey,
  recaptchaSiteKey,
  component,
  itemId,
  showVariationsError,
  showQuestionsAiModal,
  saveFrontendStatsd,
  runCatchErrorBoundary,
  ...rest
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [tokenResponseV2, setTokenResponseV2] = useState(responseToken);

    const verifyCallback = token => {
      setTokenResponseV2(token);
      if (updateTokenResponse) {
        updateTokenResponse({ token });
      }
    };

    useEffect(() => {
      setTokenResponseV2(responseToken);
    }, [responseToken]);

    return (
      <Questions {...rest} quick_access={quick_access} question_message={question_message}>
        {rest.custom_message && (
          <QuestionWarningMessage customMessage={rest.custom_message} showVariationsError={showVariationsError} />
        )}
        {rest.ask_questions_ai && showQuestionsAiModal && (
          <div className={`${namespace}__questions-ai`}>
            <Action
              showQuestionsAiModal={showQuestionsAiModal}
              actionType={rest.ask_questions_ai.action_type}
              deviceType="mobile"
              hierarchy="loud"
              fullWidth
              saveFrontendStatsd={saveFrontendStatsd}
              {...rest.ask_questions_ai}
            />
          </div>
        )}
        {rest.user_questions && (
          <QuestionsList
            isUsersQuestions
            showTitle
            userQuestions={rest.user_questions}
            questionsList={rest.user_questions}
          />
        )}
        {rest.others_questions && (
          <OthersQuestionsMobile showTitle questionsList={rest.others_questions} show_more={rest.show_more} />
        )}
        {rest.empty_state && (
          <NoQuestions empty_state={rest.empty_state} className={`${namespace}__no-questions--mobile`} />
        )}
        {rest.form && rest.form.additional_actions && (
          <>
            <ActionBuyBox
              className={`${namespace}__additional-actions`}
              actions={rest.form.additional_actions}
              fetchContactInfo={fetchContactInfo}
              recaptchav2token={tokenResponseV2}
              itemId={rest.form.confirm ? rest.form.confirm.item_id : itemId}
              redirectToLogin={redirectToLogin}
              recaptchaSiteKey={recaptchaSiteKey}
              featureName="question"
              isFetchingData={rest.isFetchingData}
            />
            {recaptchaPublicKey && (
              <Recaptchav2
                align="center"
                recaptchaPublicKey={recaptchaPublicKey}
                required
                lowEnd
                id="question-call-recaptcha"
                verifyCallback={verifyCallback}
              />
            )}
          </>
        )}
      </Questions>
    );
  } catch (error) {
    /* eslint-enable react-hooks/rules-of-hooks */
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

QuestionsMobile.propTypes = {
  quick_access: shape({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
    title: shape({ text: string.isRequired }).isRequired,
  }),
  question_message: shape({
    title: shape({ text: string.isRequired }).isRequired,
    subtitle: shape({ text: string.isRequired }).isRequired,
  }),
  fetchContactInfo: func,
  recaptchaPublicKey: string,
  recaptchaSiteKey: string,
  component: node,
  itemId: string,
  responseToken: string,
  updateTokenResponse: func,
  redirectToLogin: func,
  showVariationsError: func,
  showQuestionsAiModal: func,
  saveFrontendStatsd: func,
  runCatchErrorBoundary: func,
};

QuestionsMobile.defaultProps = {
  question_message: null,
  quick_access: null,
  fetchContactInfo: null,
  recaptchaPublicKey: null,
  recaptchaSiteKey: null,
  component: null,
  itemId: null,
  responseToken: null,
  updateTokenResponse: null,
  redirectToLogin: null,
  showVariationsError: () => {},
  saveFrontendStatsd: () => {},
  runCatchErrorBoundary: () => {},
};

export default QuestionsMobile;
export const MappedQuestionsMobile = props => <QuestionsMobile {...jsonToProps(props)} />;
